body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #121212; /* Темный фон для всего сайта */
  color: #FFFFFF; /* Основной цвет текста */
  overflow-x: hidden; /* Отключаем горизонтальную прокрутку */
}

.App {
  position: relative; /* Родительский контейнер для фона */
  text-align: center;
  padding: 20px;
  z-index: 1; /* Позиционирование для контента поверх видео */
}

/* Видео на фоне */
.background-video {
  position: fixed; /* Фиксированное положение для фона */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Подгоняет видео под контейнер */
  z-index: -1; /* Устанавливает его за всем остальным содержимым */
  pointer-events: none; /* Отключает взаимодействие */
  background-color: black; /* Черный фон, когда видео не загружается */
}


/* Стили для заголовка */
.header {
  padding: 60px 20px;
  margin-bottom: 40px;
}

.header-title {
  font-size: 48px;
  margin: 0;
  color: #FFD700; /* Золотой цвет для названия компании */
}

.header-subtitle {
  font-size: 24px;
  color: #CCCCCC; /* Серый цвет для подзаголовка */
}

/* Стили для разделов */
.about,
.services {
  background-color: rgba(30, 30, 30, 0.8); /* Полупрозрачный темный фон для разделов */
  padding: 40px 20px;
  margin-bottom: 40px;
  border-radius: 8px; /* Скругленные углы */
}

.section-title {
  font-size: 36px;
  color: #FFD700;
  margin-bottom: 20px;
}

.section-text {
  font-size: 18px;
  color: #CCCCCC;
  max-width: 800px;
  margin: 0 auto;
}

/* Стили для услуг */
.service-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.service-item {
  background-color: rgba(46, 46, 46, 0.8); /* Полупрозрачный фон для выделения */
  padding: 20px;
  border-radius: 8px;
  color: #FFFFFF;
  text-align: left;
}

.service-item h3 {
  margin: 0 0 10px 0;
  color: #FFD700;
}

.service-item p {
  margin: 0;
  color: #CCCCCC;
}

/* Стили для футера */
.footer {
  background-color: #1F1F1F;
  padding: 20px;
  margin-top: 40px;
}

.footer-text,
.footer-contact {
  margin: 5px 0;
  color: #CCCCCC;
}
.service-item {
  background-color: rgba(46, 46, 46, 0.8);
  padding: 20px;
  border-radius: 8px;
  color: #FFFFFF;
  text-align: left;
  margin-bottom: 10px;
}

.service-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer; /* Указатель мыши */
}

.toggle-button {
  background: none;
  border: none;
  color: #FFD700;
  font-size: 24px;
  cursor: pointer;
}

.service-item p {
  margin: 10px 0 0;
  color: #CCCCCC;
}

.footer-contact {
  margin-top: 20px;
}

.contact-icon {
  display: inline-block;
  margin: 0 10px;
}

.contact-icon img {
  width: 30px; /* Размер иконки */
  height: 30px;
  filter: invert(100%); /* Белый цвет для темного фона */
}
.logo {
  width: 100px; /* Увеличиваем ширину логотипа */
  height: auto; /* Сохраняем пропорции */
  margin-right: 15px; /* Отступ справа */
  vertical-align: middle; /* Выравнивание по центру */
}
.benefits {
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.8); /* Прозрачный темный фон */
  border-radius: 8px;
  margin: 40px auto;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.section-title {
  font-size: 28px;
  font-weight: bold;
  color: #ffd700; /* Золотистый цвет для заголовка */
  margin-bottom: 20px;
  text-align: center;
}

.benefits-list {
  list-style: none;
  padding: 0;
}

.benefit-item {
  display: flex;
  align-items: center;
  background-color: #333; /* Темный фон для элементов списка */
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 6px;
  color: #fff; /* Белый цвет для текста */
  transition: background-color 0.3s ease;
}

.benefit-item:hover {
  background-color: #444; /* Более светлый темный фон при наведении */
}

.benefit-icon {
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.benefit-icon svg {
  width: 36px;
  height: 36px;
  color: #ffd700; /* Золотистый цвет для иконок */
}


.faq-item {
  background-color: rgba(46, 46, 46, 0.8); /* Полупрозрачный темный фон, как у раздела услуг */
  margin: 20px auto; /* Отступы между элементами и авто центрирование */
  padding: 20px; /* Внутренние отступы аналогичны услугам */
  border-radius: 8px; /* Радиус границ для округлости, как у услуг */
  cursor: pointer; /* Курсор "рука" */
  transition: background-color 0.3s ease; /* Плавный переход фона при наведении */
  color: #FFFFFF; /* Цвет текста */
  text-align: left; /* Выравнивание текста */
  max-width: 800px; /* Ограничение ширины для центрирования */
  width: 100%; /* Ширина блока 100% до ограничения */
}

.faq-item:hover {
  background-color: rgba(56, 56, 56, 0.8); /* Светлее фон при наведении */
}

.faq-question {
  display: flex; /* Flexbox для расположения вопросов и кнопок */
  justify-content: space-between; /* Разделение по краям */
  align-items: center; /* Центрирование по вертикали */
}

.faq-question h3 {
  color: #FFD700; /* Цвет заголовка аналогичен разделу услуг */
  margin: 0 0 10px 0; /* Убираем отступы и добавляем нижний отступ */
  font-size: 1.2em; /* Размер шрифта */
  flex-grow: 1; /* Занимаем доступное пространство */
}

.toggle-button {
  font-size: 24px; /* Размер значка "+" */
  color: #FFD700; /* Цвет значка "+" */
  background: none; /* Убираем фон */
  border: none; /* Убираем границы */
  cursor: pointer; /* Курсор "рука" */
  outline: none; /* Убираем контур при фокусе */
}

.faq-answer {
  margin-top: 10px; /* Отступ сверху */
  color: #CCCCCC; /* Цвет текста аналогичен разделу услуг */
  font-size: 18px; /* Размер текста аналогичен разделу услуг */
  padding: 10px 0 0 0; /* Внутренние отступы */
  display: block; /* Блоковое отображение для текста */
  max-width: 800px; /* Ограничение ширины как у услуг */
  margin: 0 auto; /* Центрирование текста */
}

.contact-form {
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.8); /* Прозрачный темный фон */
  border-radius: 8px;
  margin: 40px auto;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.form {
  display: flex;
  flex-direction: column;
}

.section-title {
  font-size: 28px;
  font-weight: bold;
  color: #ffd700; /* Золотистый цвет для заголовка */
  margin-bottom: 20px;
  text-align: center;
}

.form-input, .form-textarea {
  background-color: #222; /* Темный фон для полей ввода */
  border: 1px solid #444;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 15px;
  color: #fff; /* Белый цвет текста */
  font-size: 16px;
}

.form-input::placeholder, .form-textarea::placeholder {
  color: #bbb; /* Серый цвет плейсхолдера */
}

.form-textarea {
  resize: vertical; /* Ограничить изменение размера только по вертикали */
  min-height: 100px;
}

.form-button {
  background-color: #ffd700; /* Золотистая кнопка */
  border: none;
  border-radius: 6px;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #000; /* Черный цвет текста */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-button:hover {
  background-color: #e6c200; /* Темнее при наведении */
}
.footer {
  text-align: center; /* Центрирование текста в футере */
  background-color: #1e1e1e; /* Цвет фона */
  padding: 5px 0; /* Уменьшенные внутренние отступы сверху и снизу */
  margin: 0; /* Убираем внешние отступы */
  width: 100%; /* Ширина на всю страницу */
}


